﻿import $ from "jquery";
import * as constants from "../constants.js";

export const DataHelper = {
    getCookie: function (name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2)
            return parts.pop().split(';').shift();
    },

    getOssApiToken: function () {
        const val = this.getCookie("OssApiToken");
        return val;
    },

    getOssConfig: function (cb) {
        $.ajax({
            url: "/home/getossconfig",
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb({
                    ossDomain: response.ossConfig.domain
                });
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getUserId: function (cb) {
        $.ajax({
            url: "/account/getuserid",
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getUser: function (userId, cb) {
        $.ajax({
            url: "/user/getuser?userId=" + userId,
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getSchoolCode: function (cb) {
        $.ajax({
            url: "/school/getschoolcode",
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getSettings: function (cb) {
        $.ajax({
            url: "/school/getsettings",
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getOssApiRequiredDetails: function (cb) {
        const self = this;

        let details = {
            ossDomain: "",
            schoolCode: ""
        };

        self.getOssConfig(function (ossConfig) {
            details.ossDomain = ossConfig.ossDomain

            self.getSchoolCode(function (schoolCode) {
                details.schoolCode = schoolCode

                cb(details);
            });
        });
    },

    getUserRegistrationRecords: function (userId, cb) {
        this.getOssApiRequiredDetails(function () {
            $.ajax({
                url: "/enrollment/getuserregistrations?userId=" + userId,
                method: "GET",
                contentType: "application/json; charset=utf-8",
            })
            .done((response, textStatus, xhr) => {
                if (textStatus === "success") {
                    cb(response);
                }
            })
            .fail((xhr, textStatus, errorThrown) => {
                console.log(errorThrown);
            });
        });
    },

    getUserRegistrations: function (ossConfig, user, cb) {
        let parentId = null;
        if (user.ossAssociatedRefId !== null) {
            if (user.ossAssociatedRefType.toUpperCase() === constants.OssAssociatedRefType.Parent.toUpperCase()) {
                parentId = user.ossAssociatedRefId;
            }
        }

        let url = ossConfig.ossDomain + "/api/registrations/byassociation?accesskey=" + user.accessKey + "&activeOnly=true";
        if (parentId !== null)
            url += "&parentid=" + parentId;

        const self = this;
        $.ajax({
            url: url,
            method: "GET",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + self.getOssApiToken());
                xhr.setRequestHeader("SchoolCode", ossConfig.schoolCode);
            }
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    getUserStudents: function (ossConfig, user, includes, cb) {
        let parentId = null;
        if (user.ossAssociatedRefId !== null) {
            if (user.ossAssociatedRefType.toUpperCase() === constants.OssAssociatedRefType.Parent.toUpperCase()) {
                parentId = user.ossAssociatedRefId;
            }
        }

        let url = ossConfig.ossDomain + "/api/students/byassociation?accesskey=" + user.accessKey + "&activeOnly=true";
        if (parentId !== null)
            url += "&parentid=" + parentId;
        if (includes !== null)
            url += "&includes=" + includes;

        const self = this;
        $.ajax({
            url: url,
            method: "GET",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + self.getOssApiToken());
                xhr.setRequestHeader("SchoolCode", ossConfig.schoolCode);
            }
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    },

    createRegistrationRecordIfNotExist: function (regRecord, cb) {
        const data = {
            ossRegId: regRecord.ossRegId,
            ossTermId: regRecord.ossTermId,
            ossLevelId: regRecord.ossLevelId,
            userId: regRecord.userId
        };

        $.ajax({
            url: "/enrollment/createregistrationifnotexist",
            method: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: JSON.stringify(data)
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                cb(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    }
};